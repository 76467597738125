

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
 
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function PRPvampirefaq() {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about chin / jaw filler</Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       



        <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
What does the treatment involve?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The treatment is carried out by injecting dermal fillers strategically to the chin, jowl area or jawline, with a cannula/needle, delivering results that are suitable for the patient. Treatment results are instant and the treatment time typically lasts between 10-15 minutes.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
          </Col>


          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Does the treatment hurt?
       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Numbing cream is applied to the area to be injected and only when it is numbed, the treatment is done, making your experience as comforting as possible. Based on all our previous patients, the pain was mild and some patients felt no pain at all.


        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
          </Col>

          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
        <Accordion allowZeroExpanded className="shadow">
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
What happens on the day?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We start the appointment with a thorough consultation to understand your requirements and suitability. Once we have a full understanding and you’re happy to proceed, we start the treatment, which takes approximately 10-15 minutes.

        </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
          </Col>

          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
        <Accordion allowZeroExpanded className="shadow">
 
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long is recovery?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        No recovery time needed. You can get straight back to your daily routine, without taking time off work.

        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
          </Col>

          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
Why choose Dermamina        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We are renowned for providing natural looking yet life changing results, and being completely honest with our patients. If we feel a treatment is not suitable for you, we will not recommend for you to go ahead with it.

        </p>
    </AccordionItemPanel>
</AccordionItem>
</Accordion>    
          </Col>
          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded className="shadow">

          <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What if I change my mind after the consultation?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        In the case of changing your mind after the consultation, you will be charged £20 for the consultation and the remaining will be refunded or can be used towards another treatment. 

        </p>
    </AccordionItemPanel>
</AccordionItem>
</ Accordion>
</ Col>




        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}







